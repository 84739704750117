  
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const StrukturPageTemplate = ({
  content,
  nama,
  image,
}) => (
  <div className="content">
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          {content && content.map(content => (
            <div className="section">
              <h2>{content.nama}</h2>
              <PreviewCompatibleImage
                imageInfo={{
                  image: content.image,
                  alt: content.nama,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  </div>
)

StrukturPageTemplate.propTypes = {
  content: PropTypes.shape({
    nama: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),

}

const StrukturPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <StrukturPageTemplate
        content={frontmatter.content}
        nama={frontmatter.content.nama}
        image={frontmatter.content.image}
      />
    </Layout>
  )
}

StrukturPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default StrukturPage

export const strukturPageQuery = graphql`
  query StrukturPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        content {
          nama
          image {
              childImageSharp {
                fluid(maxWidth: 1250, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
          }
        }
      }
    }
  }
`